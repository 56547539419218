import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select, InputNumber } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import DetailTable from './DetailTable';
import { getCurrencyInfo } from 'utils/GlobalFunctions';
const { Option } = Select;

function TableWidget(props) {
    const [isBusy, setIsBusy] = useState(false);
    const { dataList, setDataList, approveAction } = props;
    if (dataList === undefined) return <div />

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Customer Name",
            dataIndex: "customer_name",
            align : "center",
            sorter: {
                compare: (a, b) => a.customer_name.localeCompare(b.customer_name),
            },
        },
        {
            title: "Table Name",
            dataIndex: "table_name",
            align : "center",
            sorter: {
                compare: (a, b) => a.table_name.localeCompare(b.table_name),
            },
        },
        {
            title: "Total budget",
            dataIndex: "totalBudget",
            align : "center",
            sorter: {
                compare: (a, b) => a.totalBudget - b.totalBudget,
            },
            render: text => <span>{ "$ " + parseFloat(text).toFixed(4)}</span>,
        },
        {
            title: "Currency",
            dataIndex: "payment_currency",
            align : "center",
            sorter: {
                compare: (a, b) => a.payment_currency.localeCompare(b.payment_currency),
            },
        },
        {
            title: "Pay GateWay",
            dataIndex: "pay_gateway",
            align : "center",
            sorter: {
                compare: (a, b) => a.pay_gateway.localeCompare(b.pay_gateway),
            },
        },
        {
            title: "Pay Status",
            dataIndex: "payment_status",
            align : "center",
            sorter: {
                compare: (a, b) => a.payment_status.localeCompare(b.payment_status),
            },
            render: (text, record) => {
                if (record.payment_status === null || record.payment_status === undefined || record.payment_status === '') {
                    return (
                        <div>--</div>
                    )
                }
                else {
                    return (
                        <div>{record.payment_status}</div>
                    )
                }
            },
        },
        {
            title: "Created Date",
            dataIndex: "add_timestamp",
            align : "center",
            sorter: {
                compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
            },
        },
        {
            title: "Updated Date",
            dataIndex: "update_timestamp",
            align : "center",
            sorter: {
                compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
            },
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            render: (text, record) => {
                return (
                    <div size="middle" direction={"vertical"}>
                        <a className="edit-btn"
                            onClick={async () => {
                                var res = await showConfirmDlgWidget({ title: "Approve Confirmation", content: "Are you sure to approve this order?" });
                                if (res == false) return;
                                approveAction(record)
                            }}
                        >
                            Approve
                        </a>
                        {/* <a style={{ color: "var(--redColor)", marginLeft: 20 }} className="del-btn" onClick={async () => {
                            var res = await showConfirmDlgWidget({ title: "Cacel Confirmation", content: "Are you sure to cancel this order?" });
                            if (res == false) return;
                            //                        onDeleteAction(record)
                        }}>
                            Cancel
                        </a> */}
                    </div>
                );
            },
        },
    ];
    const dataSource = dataList.map((info) => {
        return { ...info, key: info.id };
    });

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
                expandedRowRender={record => {
                    return <DetailTable
                        dataList={record.cartList} />
                }}
            />
        </div>
    )
}

export default withRouter(TableWidget)
