import React, { useEffect, useRef, useState } from "react";
import { DeleteOutlined, EditOutlined, PrinterOutlined } from '@ant-design/icons';
import "./SaleItemWidget.css"
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import { Col, Row } from 'antd';
import ExtraOptionItems from '../ExtraOptionItems/ExtraOptionItems';
import RecipeExtraItems from '../RecipeExtraItems/RecipeExtraItems';
import { ComponentToMultiPrint, ComponentToPrint } from 'components/PrintView/PrintView';
import { useReactToPrint } from "react-to-print";
import { showMultiPrintDialog } from "./MultiPrintDialog/showMultiPrintDialog";
import { Checkbox } from 'antd';
import type { CheckboxProps } from 'antd';

function SaleItemWidget(props) {
  const componentRef = useRef();
  const componentMultiRef = useRef();

  const [searchList, setSearchList] = useState([]);
  const [multiDataList, setMultiDataList] = useState([]);

  const { info, approveAction, cancelAction, deliverAction, paymentConfirmAction, selectOption, type, settingData, selectedOrderAction } = props

  useEffect(() => {
    setupSearchList([info])
  }, [info]);
  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  const setupSearchList = (dataList) => {
    let group_data = groupBy(dataList, 'table_name')
    var result = []
    Object.keys(group_data).forEach(function (key) {
      result.push(group_data[key]);
    });
    setSearchList(result)
  }
  const itemUI = info.cartList == undefined || info.cartList.length == 0 ? <div>You don't have any Item list</div> : info.cartList.map((item, index) => {
    return (
      <div key={index}>
        <div style={{ backgroundColor: item.fill_color, "color": "white", padding: 5 }}>
          <Row gutter={4}>
            <Col>
              <span className='bold font-14'>{item.enter_qty}x</span>
            </Col>
            <Col>
              <div className='item-name bold font-14'>{item.name}</div>
              <div className='font-15'>${parseFloat(item.total_price).toFixed(2)}&nbsp;{info.payment_currency}</div>
            </Col>
          </Row>
          <div>
            <RecipeExtraItems
              optionItemString={item.recipe_extra}
            />
          </div>
          <div>
            <ExtraOptionItems
              optionItemString={item.extraOptionList}
            />
          </div>
          <div className='item-note font-14'>{item.note}</div>
        </div>
        {info.cartList.length != index + 1 && <div className='item-body-bottom'></div>}
      </div>
    );
  });

  const handleMultiPrintAction = async (pInfo) => {
    let record = pInfo
    var res = await showMultiPrintDialog({
      title: "Print Multi Recipe",
      info: record,
      isEditing: false
    });
    if (res === null) return;
    let group_id_list = res.group_id_list
    let nameList = res.nameList
    if (group_id_list) {
      let result = []
      let cartList = pInfo.cartList
      group_id_list.map((childList, index) => {
        let tmpResult = { ...pInfo }
        let tmpCartList = []
        let totalBudget = 0
        let customer_name = ""
        childList.map((info, index) => {
          let person_name_id = info.person_name_id
          let recipe_id = info.recipe_id
          customer_name = nameList.filter((x) => x.id === person_name_id)[0].name
          let cartItem = cartList.filter((x) => x.id === recipe_id)[0]
          totalBudget += parseFloat(cartItem.total_price)
          tmpCartList.push(cartItem)
        })
        tmpResult["cartList"] = tmpCartList
        tmpResult["customer_name"] = customer_name
        tmpResult["totalBudget"] = totalBudget
        result.push([tmpResult])
      })
      setMultiDataList(result)
    }
    setTimeout(() => {
      handleMultiPrint()
    }, "500");
  }
  const handleMultiPrint = useReactToPrint({
    content: () => componentMultiRef.current,
  });
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const onChange: CheckboxProps['onChange'] = (e) => {
    selectedOrderAction(info, e.target.checked)
  };
  return (
    <div className='one-item'>
      {selectOption && <div style={{ padding: 2 }}>
        <Checkbox onChange={onChange}>Select this order.</Checkbox>
      </div>}
      <div className='item-header font-15'>
        {type !== "2" ?
          <>
            <Row justify='space-between'>
              <Col>
                <span className='font-14'>No. <span className='font-16'>{info.orderIndex}</span></span>
              </Col>
            </Row>
            <Row justify='space-between'>
              <Col style={{ paddingRight: 20 }}>
                <span>{info.add_timestamp}</span>
              </Col>
              <Col>
                <p className='customer-name'>{info.customer_name}.</p>
              </Col>
            </Row>
          </>
          :
          <Row justify='space-between'>
            <Col>
              <span>{info.add_timestamp}</span>
            </Col>
            <Col>
              <p className='customer-name'>{info.customer_name}.</p>
            </Col>
          </Row>
        }
        <Row justify='space-between'>
          <Col>
            <span>{info.table_name}</span>
          </Col>
          <Col>
            <span>{info.pay_gateway}</span>
          </Col>
        </Row>
      </div>
      <div className='item-body'>
        {itemUI}
      </div>
      {type == "0" && (<div>
        <button className="btn_order_normal"
          onClick={() => {
            approveAction(info)
          }}
        >
          <span>Approve Order</span>
        </button>
        <button className="btn_cancel_order"
          onClick={() => {
            cancelAction(info)
          }}
        >
          <span>Cancel Order</span>
        </button>
      </div>)}

      {type == "1" && (<div>
        <button className="btn_order_normal"
          onClick={() => {
            deliverAction(info)
          }}
        >
          <span>Deliver Order</span>
        </button>
        <button className="btn_cancel_order"
          onClick={() => {
            cancelAction(info)
          }}
        >
          <span>Cancel Order</span>
        </button>
      </div>)}

      {type == "2" && (info.payment_status === null || info.payment_status === undefined || info.payment_status === '') && (<button className="btn_order_normal"
        onClick={() => {
          paymentConfirmAction(info)
        }}
      >
        <span>Pay Check</span>
      </button>)}

      {type == "2" && (<button className="btn-print"
        onClick={() => {
          handlePrint()
        }}
      >
        <span><PrinterOutlined /> &nbsp;Print Receipt</span>
      </button>)}
      {type == "2" && (<button className="btn-multi-print"
        onClick={() => {
          handleMultiPrintAction(info)
        }}
      >
        <span><PrinterOutlined /> &nbsp;Print Multi Receipt</span>
      </button>)}
      <div style={{ display: "none" }}>
        <ComponentToPrint
          ref={componentRef}
          dataList={searchList}
          settingData={settingData}
        />
        <ComponentToMultiPrint
          ref={componentMultiRef}
          dataList={multiDataList}
          settingData={settingData}
        />
      </div>
    </div>
  )
}

export default SaleItemWidget
