// @flow strict

import { message } from "antd";
import TextButton from "components/ButtonWidgets/TextButton/TextButton";
import React, { useEffect, useState } from "react";
import { apiResendActivationCode } from "services/userServices";

import "./ResendCodeWidget.css";
const defaultWaitTime = 60;
function ResendCodeWidget(props) {
  const { user_id, setIsWrong } = props;

  const [waitTime, setWaitTime] = useState(0);
  const [tick, setTick] = useState(true);
  const [intervalId, setIntervalID] = useState(null);
  const onClickResendCode = () => {
    setWaitTime(defaultWaitTime);
    setIsWrong(false);
    
  };
  useEffect(() => {
    if (waitTime === defaultWaitTime) {
      setIntervalID(setInterval(timer, 1000));
    } else if (waitTime === 0) {
      clearInterval(intervalId);
    }
  }, [waitTime]);
  const timer = () => {
    setTick((t) => !t);
  };

  useEffect(() => {
    if (waitTime > 0) {
      setWaitTime(waitTime - 1);
    }
  }, [tick]);

  return (
    <div className="resend-code-widget">
      {waitTime === 0 ? (
        <div>
          <TextButton
            onClick={onClickResendCode}
            style={{ color: "var(--blueColor)", fontSize: 16, marginRight: 20 }}
          >
            {" "}
            Resend Code
          </TextButton>
        </div>
      ) : (
        <div style={{ fontSize: 16, marginRight: 20 }}>
          Resend code in {waitTime}s
        </div>
      )}
    </div>
  );
}

export default ResendCodeWidget;
