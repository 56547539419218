import React from 'react';
import "./MainLayout.css";
const MainLayout = (props) => {
    return (
        <div className="main-page">
            {props.children}
        </div>
    );
};

export default MainLayout;