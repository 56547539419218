import React from "react";
import "./NormalButton.css";
function NormalButton(props) {
  return (
    <button
        className="green-button custom-button"
        onClick={props.onClick}
        style={props.style}
        ref={props.ref}
      >
        {props.children}
      </button>
  );
}

export default NormalButton;
