import React from "react";
import "./AuthDialogWidget.css";
function AuthDialogWidget(props) {
  return (
    <div style={{ padding: 10 }}>
      <div className="auth-dialog-widget">
        <div className={"title"}>
          {props.title}
          {props.sub_title !== undefined && <div>{props.sub_title}</div>}
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default AuthDialogWidget;
